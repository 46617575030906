import React from 'react';
import { toast } from 'react-toastify';

const iconStyle = {
    marginRight: 5
}

class Toast {

    static default = (nodeOrMsg) => toast(<><i style={iconStyle} className="fa fa-info-circle"></i>{nodeOrMsg}</>);

    static dark = (nodeOrMsg) => toast.dark(<><i style={iconStyle} className="fa fa-info-circle"></i>{nodeOrMsg}</>);

    static success = (nodeOrMsg) => toast.success(<><i style={iconStyle} className="fa fa-check"></i>{nodeOrMsg}</>);

    static info = (nodeOrMsg) => toast.info(<><i style={iconStyle} className="fa fa-info-circle"></i>{nodeOrMsg}</>);

    static warn = (nodeOrMsg) => toast.warn(<><i style={iconStyle} className="fa fa-warning"></i>{nodeOrMsg}</>);

    static error = (nodeOrMsg) => toast.error(<><i style={iconStyle} className="fa fa-exclamation-circle"></i>{nodeOrMsg}</>);

}

export default Toast;