import { cacheGet } from './cacheManager';
import { RouteManager } from './routeManager';

//import moment from './moment';

// var cachedClaims = {
//   claims: null,
//   expires: moment().add(3, 'm')
// }

const grantedPerms = {
    canView: true,
    canDelete: true,
    canGetRecord: true,
    canInsert: true,
    canUpdate: true,
    canList: true,
    canExport: true,
    canImport: true,
}

const deniedPerms = {
    canView: false,
    canDelete: false,
    canGetRecord: false,
    canInsert: false,
    canUpdate: false,
    canList: false,
    canExport: false,
    canImport: false,
}

function getPermissions(resourceCode, authorizationClaims) {
    try {
        //return grantedPerms; // Bu geliştirme aşaması için
        if (resourceCode == null) return deniedPerms;

        var claims = authorizationClaims || cacheGet('authorizationClaims');
        if (claims == null)
            return deniedPerms;

        // if (cachedClaims.claims && cachedClaims.expires > moment())
        //   claims = cachedClaims.claims
        // else {
        //   claims = localStorage.getItem('authorizationClaims');
        //   if (claims == null) return deniedPerms;
        //   claims = JSON.parse(claims);
        //   cachedClaims = {
        //     claims,
        //     expires: moment().add(3, 'm')
        //   };
        //   console.warn('Permissions cached !')
        // }

        claims = claims[resourceCode];
        if (claims == null) return deniedPerms;

        return { ...deniedPerms, ...GetActionTypesOf(claims) };
    } catch (error) {
        return deniedPerms;
    }
}

function getPermissionsMultiple(resourceCodes) {
    var claims = cacheGet('authorizationClaims');
    return resourceCodes.map(resourceCode => getPermissions(resourceCode, claims))
}

function getUserSelectedTenant()
{
  if( cacheGet('selectedTenant') != null )
    return cacheGet('selectedTenant').tenantId;
  return cacheGet('profile').tenantId;
}

function getUserSelectedTenantName()
{
  if( cacheGet('selectedTenant') != null )
    return cacheGet('selectedTenant').tenantName;
   return '';
}

function getUserSelectedTenants()
{
  var result;
  if( cacheGet('selectedTenant') != null )
    result = [cacheGet('selectedTenant').tenantId];
  else {
    result = cacheGet('profile').subTenantIds;
    result.push(cacheGet('profile').tenantId);
  }
  return result;
}

function GetActionTypesOf(claim) {
    return {
        canView: claim.View,
        canList: claim.List,
        canGetRecord: claim.GetRecord,
        canInsert: claim.Insert,
        canUpdate: claim.Update,
        canDelete: claim.Delete,
        canExport: claim.Export,
        canImport: claim.Import,
    }
}

function isGranted(resourceCode) {
    try {
        var permissions = getPermissions(resourceCode);
        //permissions = permissions[action];
        if (permissions == null) return deniedPerms;

        return permissions;
    } catch (error) {
        return deniedPerms;
    }
}

function isGrantedAsPage(pageUrl) {
    try {
        const Pages = RouteManager.GetPages;
        var pageInfo = null;

        for (var page in Pages) {
            if (Pages[page].url == pageUrl) {
                pageInfo = Pages[page];
                break;
            }
        }

        if (pageInfo == null) return grantedPerms;
        else return isGranted(pageInfo.resourceCode);
    } catch (error) {
        console.warn(error);
        return deniedPerms;
    }
}

function getFirstAuthorizedPageUrl() {
    try {
        const Pages = RouteManager.GetPages;
        var authorizedPageKey = getFirstAuthorizedPageKey();
        if (authorizedPageKey)
            return Pages[authorizedPageKey].url;
    } catch (error) {
        console.error(error);
    }
    return "401";
}

function getFirstAuthorizedPageKey() {
    try {
        const Pages = RouteManager.GetPages;
        for (var pageKey in Pages) {
            var page = Pages[pageKey];
            if (page && page.url) {
                var isAuthorized = isGranted(page.resourceCode);
                if (isAuthorized.canView)
                    return pageKey;
            }
        }
    } catch (error) {
        console.error(error);
    }
    return null;
}

function getIsAuthorizedByResourceAndClaim(resourceName, claimName) {
    var authClaims = cacheGet("authorizationClaims");
    return authClaims[resourceName] ? (authClaims[resourceName][claimName] ? true : false) : false;
}

export function checkIfPageExistAndGranted(url) {
    try {
        const Pages = RouteManager.GetPages;
        for (var pageKey in Pages) {
            var page = Pages[pageKey];
            if (page && page.url && page.url == url) {
                if(page.resourceCode === undefined) return page.url;
                var isAuthorized = isGranted(page.resourceCode);
                if (isAuthorized.canView)
                    return page.url;
            }
        }
        return null;
    } catch (e) {
        console.log(e);
    }
}

/**
 *Mainly used to indicate which page will be used for default page after login
 *
 * @return {*} 
 */
function getAllGrantedPagesForDropdown() {
    try {
        const Pages = RouteManager.GetPages;
        const dropdownOptions = [];
        for (let pageKey in Pages) {
            let page = Pages[pageKey];
            if (page && page.url) {
                dropdownOptions.push({
                    key: page.url,
                    value: page.url,
                    label: page.name
                });
            }
        }
        return dropdownOptions;
    } catch (e) {
        console.log(e);
    }
    return null;
}

export { getIsAuthorizedByResourceAndClaim,getUserSelectedTenantName ,isGranted, isGrantedAsPage, getPermissions, getUserSelectedTenant, getUserSelectedTenants, getPermissionsMultiple, getFirstAuthorizedPageUrl, getFirstAuthorizedPageKey, getAllGrantedPagesForDropdown };