
import _ from 'lodash';
import { PageInfos } from '../pages'
import { cacheGet } from './cacheManager';
import React from 'react'


export const RouteManager = {};

const FormNavigationFilter = React.lazy(() => import('../components/FormNavigationFilter'))
const GridPage = React.lazy(()=> import('../components/GridPage'));
RouteManager.GetFormMenus = () => {
    
    const formMenusFromCache = cacheGet('FormMenus');
    if (formMenusFromCache) {
        Object.keys(formMenusFromCache).map(key => 
        {
        if(formMenusFromCache[key].url) formMenusFromCache[key].component = formMenusFromCache[key].showInSidebar === undefined ?  FormNavigationFilter : GridPage;
        })
        return formMenusFromCache
    }
    return {};

}

RouteManager.GetPages = (() => {

    let formMenus = RouteManager.GetFormMenus();

    formMenus  = formMenus ? formMenus : {};

    const tempPageInfos = { ...PageInfos,...formMenus };

    for (var page in tempPageInfos) {
        tempPageInfos[page].key = page;

        if (tempPageInfos[page].url && tempPageInfos[page].path == null)
            tempPageInfos[page].path = `${tempPageInfos[page].url}`;
    }

    return tempPageInfos;
})();

RouteManager.GetPagesAsNav = (() => {
    var pages = _.values(_.cloneDeep(RouteManager.GetPages)).filter(page => page.showInSidebar !== false);
    var toBeDeleted = [];
    for (var page of pages) {
        if (page.defaultOpen) {
            delete page.defaultOpen
            if (!page.url)
                page.url = '';
        }
        if (page.parentResourceCode) {

            var foundPage = pages.find(x => page.parentResourceCode && x.resourceCode == page.parentResourceCode);
            if (foundPage) {
                if (foundPage.children == null)
                    foundPage.children = [];

                foundPage.children.push(page);
                toBeDeleted.push(pages.findIndex(x => x.key == page.key));
            }
        }
    }

    toBeDeleted.sort((a, b) => a - b);
    toBeDeleted.reverse();

    for (var ind of toBeDeleted) {
        pages.splice(ind, 1);
    }
    return pages;
})();

RouteManager.GetPagesAsRoute = (() => {
    var pages = _.values(_.cloneDeep(RouteManager.GetPages));

    for (var page of pages) {
        if (page.url && page.parentResourceCode == null)
            page.exact = true;
    }

    return pages;
})();

