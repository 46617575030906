import React from 'react';
import _ from 'lodash';
import { getLocalizedText } from './common/localizationManager';



const RuleConfig = React.lazy(() => import('./views/Routes/ETL/RuleConfig/RuleConfig'));
// const Scheduler = React.lazy(() => import('./views/Routes/Management/Scheduler/Scheduler'));
const AuthTemplates = React.lazy(() => import('./views/Routes/Management/AuthTemplates/AuthTemplates'));
const Dashboard = React.lazy(() => import('./views/Routes/Management/Dashboard/Dashboard'));
const Tenant = React.lazy(() => import('./views/Routes/Management/Tenant/Tenant'));
const Users_Mng = React.lazy(() => import('./views/Routes/Management/Users/UsersPage'));
const UserForm = React.lazy(() => import('./views/Routes/Management/Users/UsersFormPage'));

const ResourceDefinition_Mng = React.lazy(() => import('./views/Routes/Management/ResourceDefinition/ResourceDefinition'));
const Parameters_Mng = React.lazy(() => import('./views/Routes/Management/Parameters/Parameters'));
// const Kibana = React.lazy(() => import('./views/Routes/Management/Kibana/Kibana'));
const Department = React.lazy(() => import('./views/Routes/Management/Department/Department'));
const CommunicationDefinitions_Mng = React.lazy(() => import('./views/Routes/Management/CommunicationDefinitions/CommunicationDefinitions'));
const CommunicationTemplates_Mng = React.lazy(() => import('./views/Routes/Management/CommunicationTemplates/CommunicationTemplates'));
const Notifications_Mng = React.lazy(() => import('./views/Routes/Management/Notifications/Notifications'));
const AssignmentFormSubmit = React.lazy(() => import('./views/Routes/Form/AssignmentFormSubmit'));
const TransactionLogs_Mng = React.lazy(() => import('./views/Routes/Management/TransactionLogs/TransactionLogs'));
const ChangePassword = React.lazy(() => import('./views/Routes/CommonPages/ChangePassword/ChangePassword'));
var AdminPages = {
    Dashboard: {
        name: getLocalizedText("DASHBOARD"),
        url: `/dashboard`,
        component: Dashboard,
        icon: 'icon-speedometer',
    },
    Title_Admin: {
        title: true,
        name: getLocalizedText("TITLE_ADMIN"),
    },
    Menu_Admin: {
        name: getLocalizedText("ADMINISTRATION"),
        resourceCode: 'AdminPages',
        icon: 'cui-dashboard',
        defaultOpen: false
    },
    Tenant: {
        name: getLocalizedText('TENANTS'),
        url: '/tenant',
        component: Tenant,
        parentResourceCode: 'AdminPages',
        resourceCode: 'Tenant_Res',
        icon: 'icon-book-open'
    },
    Department: {
        name: getLocalizedText("DEPARTMENTS_TITLE"),
        url: '/department',
        component: Department,
        parentResourceCode: 'AdminPages',
        resourceCode: 'CoreDepartment_Res',
        icon: 'icon-puzzle',
    },
    AuthTemplate_Mng: {
        name: getLocalizedText("AUTH_TEMPLATES"),
        url: '/authTemplates',
        component: AuthTemplates,
        parentResourceCode: 'AdminPages',
        resourceCode: 'AuthTemplates',
        icon: 'icon-docs',
    },
    Users_Mng: {
        name: getLocalizedText("USERS"),
        url: '/users',
        component: Users_Mng,
        parentResourceCode: 'AdminPages',
        resourceCode: 'Users',
        icon: 'icon-people',
    },
    UserForm: {
        name: getLocalizedText("USERS"),
        url: '/user/:userId?',
        component: UserForm,
        parentResourceCode: 'AdminPages',
        resourceCode: 'Users',
        icon: 'icon-people',
        showInSidebar:false
    },
    ResourceDefinition_Mng: {
        name: getLocalizedText("RESOURCE_DEFINITION"),
        url: '/resource-definitions',
        component: ResourceDefinition_Mng,
        parentResourceCode: 'AdminPages',
        resourceCode: 'ResourceDefinition',
        icon: 'icon-layers',
    },
    Parameters_Mng: {
        name: getLocalizedText("PARAMETERS"),
        url: '/parameters',
        component: Parameters_Mng,
        parentResourceCode: 'AdminPages',
        resourceCode: 'SystemParameters',
        icon: 'icon-list',
    },
    Menu_Communications: {
        name: getLocalizedText('COMMUNICATION_SETTINGS_LABEL'),
        resourceCode: 'Communication',
        parentResourceCode: 'AdminPages',
        icon: 'icon-feed',
    },
    CommunicationDefinitions_Mng: {
        name: getLocalizedText('COMMUNICATION_DEFINITIONS'),
        url: '/communicationDefinitions',
        component: CommunicationDefinitions_Mng,
        parentResourceCode: 'Communication',
        resourceCode: 'CommunicationDefinitions_tab',
        icon: 'icon-note',
    },
    CommunicationTemplates_Mng: {
        name: getLocalizedText('COMMUNICATION_TEMPLATES'),
        url: '/communicationTemplates',
        component: CommunicationTemplates_Mng,
        parentResourceCode: 'Communication',
        resourceCode: 'CommunicationTemplates_tab',
        icon: 'icon-envelope',
    },
    AuditLogs_Mng: {
        name: getLocalizedText('AUDIT_LOGS'),
        url: '/transactionLogs',
        component: TransactionLogs_Mng,
        parentResourceCode: 'AdminPages',
        resourceCode: 'TransactionLogs_Res',
        icon: 'icon-book-open',
    },
    Notifications: {
        name: getLocalizedText("NOTIFICATIONS"),
        url: '/notifications/:id?',
        component: Notifications_Mng,
        icon: 'icon-badge',
        showInSidebar: false,
    },
    FormAssignment: {
        name: getLocalizedText("FORM_ASSIGNMENT"),
        url: '/assigned-form',
        component: AssignmentFormSubmit,
        icon: 'icon-badge',
        showInSidebar: false,
    },
    ChangePassword: {
        name: getLocalizedText('CHANGE_PASSWORD_LABEL'),
        url: '/changePassword',
        component: ChangePassword,
        showInSidebar: false,
    },
    Title_GocturMain: {
        title: true,
        name: getLocalizedText("TITLE_GOCTURMAIN"),
    }
};



const ReportInformation = React.lazy(() => import('./views/Routes/GocturReports/ReportInformation/ReportInformation'));
const ReportScreen = React.lazy(() => import('./views/Routes/GocturReports/ReportScreen/ReportScreen'));

var GocturReports = {
    Menu_BI_Reports: {
        name: getLocalizedText("GOCTURBIREPORTS"),
        resourceCode: 'Reports_Res',
        icon: 'cui-pie-chart',
        defaultOpen: false
    },
    ReportInformation: {
        name: getLocalizedText('REPORT_INFORMATION'),
        url: '/report-information',
        component: ReportInformation,
        resourceCode: 'ReportInformation_Res',
        parentResourceCode: 'Reports_Res',
        icon: 'cui-chart',
    },
    ReportScreen: {
        name: getLocalizedText('REPORT_SCREEN'),
        url: '/report-screen',
        component: ReportScreen,
        resourceCode: 'ReportScreen_Res',
        parentResourceCode: 'Reports_Res',
        icon: 'cui-chart',
    },
};

const BudgetScenario = React.lazy(() => import('./views/Routes/GocturBudget/BudgetScenario/BudgetScenario'));
const BudgetStatus = React.lazy(() => import('./views/Routes/GocturBudget/BudgetStatus/BudgetStatus'));
const BudgetType = React.lazy(() => import('./views/Routes/GocturBudget/BudgetType/BudgetType'));
const BudgetConfigurationScenario = React.lazy(() => import('./views/Routes/GocturBudget/BudgetConfigurationScenario/BudgetConfigurationScenario'));
const BudgetMappingScenario = React.lazy(() => import('./views/Routes/GocturBudget/BudgetMappingScenario/BudgetMappingScenario'));
const BudgetGroupResource = React.lazy(() => import('./views/Routes/GocturBudget/BudgetGroupResource/BudgetGroupResource'));
const BudgetForecast = React.lazy(() => import('./views/Routes/GocturBudget/BudgetForecast/BudgetForecast'));

var GocturBudget = {
    Menu_Report_Budget: {
        name: getLocalizedText("GOCTURBUDGET"),
        resourceCode: 'BudgetPages',
        icon: 'cui-cog',
        defaultOpen: false
    },
    BudgetScenario: {
        name: getLocalizedText('BUDGETSCENARIO'),
        url: '/budgetScenario',
        component: BudgetScenario,
        resourceCode: 'BudgetScenario_Res',
        parentResourceCode: 'BudgetPages',
        icon: 'icon-energy',
    },
    BudgetForecast: {
        name: getLocalizedText('BUDGETFORECAST'),
        url: '/budgetForecast',
        component: BudgetForecast,
        resourceCode: 'BudgetForecast_Res',
        parentResourceCode: 'BudgetPages',
        icon: 'icon-energy',
    },
    Menu_Budget_Config: {
        name: getLocalizedText("SETTINGS_TITLE"),
        resourceCode: 'BudgetConfigPages',
        parentResourceCode: 'BudgetPages',
        icon: 'cui-cog',
        defaultOpen: false
    },
    BudgetGroupType: {
        name: getLocalizedText('GROUPS_LABEL'),
        url: '/budgetGroupType',
        component: BudgetConfigurationScenario,
        resourceCode: 'BudgetGroupType_Res',
        parentResourceCode: 'BudgetConfigPages',
        icon: 'icon-energy',
    },
    BudgetMappingScenario: {
        name: getLocalizedText('MAPS_LABEL'),
        url: '/budgetMappingScenario',
        component: BudgetMappingScenario,
        resourceCode: 'BudgetMappingScenario_Res',
        parentResourceCode: 'BudgetConfigPages',
        icon: 'icon-energy',
    },
    BudgetStatus: {
        name: getLocalizedText('STATUS_LABEL'),
        url: '/budgetStatus',
        component: BudgetStatus,
        resourceCode: 'BudgetStatus_Res',
        parentResourceCode: 'BudgetConfigPages',
        icon: 'icon-energy',
    },
    BudgetType: {
        name: getLocalizedText('BUDGETTYPE'),
        url: '/budgetType',
        component: BudgetType,
        resourceCode: 'BudgetType_Res',
        parentResourceCode: 'BudgetConfigPages',
        icon: 'icon-energy',
    },
    BudgetGroupResource: {
        name: getLocalizedText('PERMISSIONS_LABEL'),
        url: '/budgetGroupResource',
        component: BudgetGroupResource,
        resourceCode: 'BudgetGroupResource_Res',
        parentResourceCode: 'BudgetConfigPages',
        icon: 'icon-energy',
    },
};


const FormScenario = React.lazy(() => import('./views/Routes/Form/FormScenario/FormScenario'));
const FormAssignmentScenario = React.lazy(() => import('./views/Routes/Form/FormAssignmentScenario/FormAssignmentScenario'));

const Category = React.lazy(()=> import('./views/Routes/Form/Category/Category'));
const ViewDefinition = React.lazy(()=> import('./views/Routes/Form/ViewDefinition/ViewDefinition'));

var GocturForms = {
    Menu_Form: {
        name: getLocalizedText("GOCTURFORM"),
        resourceCode: 'FormPages',
        icon: 'cui-note',
        defaultOpen: false
    },
    FormScenario: {
        name: getLocalizedText('FORM_SCENARIO'),
        url: '/form-scenario',
        component: FormScenario,
        resourceCode: 'FormScenario_Res',
        parentResourceCode: 'FormPages',
        icon: 'cui-note',
    },
    FormAssignmentScenario: {
        name: getLocalizedText('FORM_SCENARIO_ASSIGN'),
        url: '/form-assignment-scenario',
        component: FormAssignmentScenario,
        resourceCode: 'FormScenarioAssign_Res',
        parentResourceCode: 'FormPages',
        icon: 'cui-calendar',
    },
    Menu_PropertyValue: {
        name: getLocalizedText('PROPERTY_VALUE'),
        resourceCode: 'PropertyValue_Res',
        icon: 'cui-monitor',
        defaultOpen: false
    },
    PropertyCategory:{
        name: getLocalizedText('CATEGORY'),
        url: '/property-category',
        component: Category,
        resourceCode: 'PropertyCategory_Res',
        parentResourceCode: 'PropertyValue_Res',
        icon: 'icon-info',
    },
    ViewDefinition:{
        name:getLocalizedText('VIEW_DEFINITION'),
        url:'/view-definitions',
        component:ViewDefinition,
        resourceCode: 'ViewDefinition_Res',
        parentResourceCode: 'PropertyValue_Res',
        icon: 'cui-list',
    }
}
// HR MODULE
const HRLog = React.lazy(() => import('./views/Routes/HumanResources/HRLog/HRLog'));
const HRDepartment = React.lazy(() => import('./views/Routes/HumanResources/Department/Department'));
const DocumentType = React.lazy(() => import('./views/Routes/HumanResources/DocumentType/DocumentType'));
const Employee = React.lazy(() => import('./views/Routes/HumanResources/Employee/Employee'));
const HotelZone = React.lazy(() => import('./views/Routes/HumanResources/HotelZone/HotelZone'));
const Operation = React.lazy(() => import('./views/Routes/HumanResources/Operation/Operation'));
const Position = React.lazy(() => import('./views/Routes/HumanResources/Position/Position'));
const Unit = React.lazy(() => import('./views/Routes/HumanResources/Unit/Unit'));
const LodgementBlock = React.lazy(() => import('./views/Routes/HumanResources/LodgementBlock/LodgementBlock'));
const ServiceRoute = React.lazy(() => import('./views/Routes/HumanResources/ServiceRoute/ServiceRoute'));
const PositionLevel = React.lazy(() => import('./views/Routes/HumanResources/PositionLevel/PositionLevel'));
const EducationMajor = React.lazy(() => import('./views/Routes/HumanResources/EducationMajor/EducationMajor'));
const ReportTemplate = React.lazy(() => import('./views/Routes/HumanResources/ReportTemplate/ReportTemplate'));

var GocturHr = {
    Menu_HR: {
        name: getLocalizedText("HUMAN_RESOURCES"),
        resourceCode: 'HumanResources',
        icon: 'cui-people',
        defaultOpen: false
    },
    HRLog: {
        name: getLocalizedText("HRLOG"),
        resourceCode: 'HRLog_Res',
        component: HRLog,
        parentResourceCode: 'HumanResources',
        icon: 'icon-book-open',
        url: '/hr-log'
    },
    Menu_Unit: {
        name: getLocalizedText("MENU_UNIT"),
        resourceCode: 'MenuUnit',
        parentResourceCode: 'HumanResources',
        icon: 'cui-puzzle',
        defaultOpen: false
    },
    Unit: {
        name: getLocalizedText('UNIT'),
        url: '/unit',
        component: Unit,
        resourceCode: 'Unit_Res',
        parentResourceCode: 'MenuUnit',
        icon: 'cui-monitor',
    },
    HRDepartment: {
        name: getLocalizedText('HR_DEPARTMENT'),
        url: '/hrdepartment',
        component: HRDepartment,
        resourceCode: 'Department_Res',
        parentResourceCode: 'MenuUnit',
        icon: 'cui-people',
    },
    Position: {
        name: getLocalizedText('POSITION'),
        url: '/position',
        component: Position,
        resourceCode: 'Position_Res',
        parentResourceCode: 'MenuUnit',
        icon: 'cui-user',
    },
    Operation: {
        name: getLocalizedText('OPERATION'),
        url: '/operation',
        component: Operation,
        resourceCode: 'Operation_Res',
        parentResourceCode: 'HumanResources',
        icon: 'icon-energy',
    },
    HR_Resources: {
        name: getLocalizedText("HR_RESOURCES"),
        resourceCode: 'HR_Resources_Res',
        icon: 'cui-task',
        defaultOpen: false,
        parentResourceCode: 'HumanResources'
    },
    HotelZone: {
        name: getLocalizedText('HOTEL_ZONE'),
        url: '/hotel-zone',
        component: HotelZone,
        resourceCode: 'HotelZone_Res',
        parentResourceCode: 'HR_Resources_Res',
        icon: 'cui-map',
    },
    LodgementBlock: {
        name: getLocalizedText('LODGEMENT'),
        url: '/lodgementBlock',
        component: LodgementBlock,
        resourceCode: 'LodgementBlock_Res',
        icon: 'cui-home',
        parentResourceCode: 'HR_Resources_Res'
    },
    ServiceRoute: {
        name: getLocalizedText('SERVICE_ROUTE'),
        url: '/serviceRoute',
        component: ServiceRoute,
        resourceCode: 'ServiceRoute_Res',
        icon: 'cui-location-pin',
        parentResourceCode: 'HR_Resources_Res'
    },
    DocumentType: {
        name: getLocalizedText('DOCUMENT_TYPE'),
        url: '/document-type',
        component: DocumentType,
        resourceCode: 'DocumentType_Res',
        parentResourceCode: 'HR_Resources_Res',
        icon: 'cui-note',
    },
    PositionLevel: {
        name: getLocalizedText('POSITION_LEVEL'),
        url: '/position-level',
        component: PositionLevel,
        resourceCode: 'PositionLevel_Res',
        parentResourceCode: 'HR_Resources_Res',
        icon: 'cui-star',
    },
    EducationMajor: {
        name: getLocalizedText('EDUCATION_MAJOR'),
        url: '/education-major',
        component: EducationMajor,
        resourceCode: 'EducationMajor_Res',
        parentResourceCode: 'HR_Resources_Res',
        icon: 'cui-bookmark',
    },
    ReportTemplate: {
        name: getLocalizedText('REPORT_TEMPLATE'),
        url: '/report-template',
        component: ReportTemplate,
        resourceCode: 'ReportTemplate_Res',
        parentResourceCode: 'HR_Resources_Res',
        icon: 'cui-bookmark',
    },
    Employee: {
        name: getLocalizedText('EMPLOYEE'),
        url: '/employee',
        component: Employee,
        resourceCode: 'Employee_Res',
        parentResourceCode: 'HumanResources',
        icon: 'icon-user',
    },
}

const Educator = React.lazy(() => import('./views/Routes/HumanResources/Educator/Educator'));
const EducationCategory = React.lazy(() => import('./views/Routes/HumanResources/EducationCategory/EducationCategory'));
const BusinessEducation = React.lazy(() => import('./views/Routes/HumanResources/BusinessEducation/BusinessEducation'));
const ProvidedBusinessEducation = React.lazy(() => import('./views/Routes/HumanResources/ProvidedBusinessEducation/ProvidedBusinessEducation'));

var GocturEducationModule = {
    BusinessEducationModule: {
        name: getLocalizedText("BUSINESS_EDUCATION_MODULE"),
        resourceCode: 'BusinessEducationModule_Res',
        icon: 'cui-bookmark',
        defaultOpen: false,
        parentResourceCode: 'HumanResources'
    },
    Educator: {
        name: getLocalizedText('EDUCATOR'),
        url: '/educator',
        component: Educator,
        parentResourceCode: 'BusinessEducationModule_Res',
        resourceCode: 'Educator_Res',
        icon: 'icon-user',
    },
    EducationCategory: {
        name: getLocalizedText('EDUCATION_CATEGORY'),
        url: '/education-category',
        component: EducationCategory,
        parentResourceCode: 'BusinessEducationModule_Res',
        resourceCode: 'EducationCategory_Res',
        icon: 'cui-list',
    },
    BusinessEducation: {
        name: getLocalizedText('BUSINESS_EDUCATION'),
        url: '/business-education',
        component: BusinessEducation,
        parentResourceCode: 'BusinessEducationModule_Res',
        resourceCode: 'BusinessEducation_Res',
        icon: 'cui-bookmark',
    },
    ProvidedBusinessEducation: {
        name: getLocalizedText('PROVIDED_BUSINESS_EDUCATION'),
        url: '/provided-business-education',
        component: ProvidedBusinessEducation,
        parentResourceCode: 'BusinessEducationModule_Res',
        resourceCode: 'ProvidedBusinessEducation_Res',
        icon: 'cui-calendar',
    }
}
const OrganizationalChart = React.lazy(() => import('./views/Routes/Shared/OrganizationalChart'));

var Common = {
    // TODO : Arrange Resources for OrganizationalChart
    OrganizationalChart: {
        name: getLocalizedText('ORGANIZATIONAL_CHART'),
        url: '/organizational-chart',
        component: OrganizationalChart,
        parentResourceCode: 'HumanResources',
        resourceCode: 'Employee_Res',
        icon: 'icon-organization',
    },
}


export var PageInfos = {
    ...AdminPages,
    ...GocturReports,
    ...GocturBudget,
    ...GocturForms,
    ...GocturHr,
    ...GocturEducationModule,
    ...Common
};


