import React from 'react';
import Constants from '../common/constants';
import { cacheGet, cacheSet } from '../common/cacheManager';
import { AuthenticationManager } from '../common/authentication'
import { HubConnectionBuilder, LogLevel, HttpTransportType } from '@microsoft/signalr';

const HubContext = React.createContext([]);
var hubConnection;

var HubContextProvider = class HubContextCls extends React.Component {

    constructor(props) {
        super(props)

        this.state = { notifications: cacheGet('notifications') || [] }
    }

    componentDidMount() {
        this.initializeConnection();
    }

    markAsRead = (notification) => {
        if (this.state.notifications == null)
            return;

        var notifications = this.state.notifications;
        var notificationInd = notifications.findIndex(x => x.notificationId = notification.notificationId);

        if (notificationInd == -1)
            return;

        //notifications[notificationInd].deliveryDate = moment();
        notifications.splice(notificationInd, 1);

        this.updateNotifications(notifications);
    }

    markAsReadAll = (notifications) => {
        if (this.state.notifications == null)
            return;

        var tempNotifications = this.state.notifications;
        tempNotifications = tempNotifications.filter(x => notifications.findIndex(y => x.notificationId == y.notificationId) != -1);

        this.updateNotifications(tempNotifications);
    }

    updateNotifications = (notifications) => {
        cacheSet('notifications', notifications, 600);
        this.setState({ notifications });
    }

    initializeConnection = () => {
        var self = this;

        hubConnection = new HubConnectionBuilder()
            .withUrl(Constants.AdminURL + '/hub', {
                accessTokenFactory: () => AuthenticationManager.GetToken(),
                transport: HttpTransportType.WebSockets
            })
            .withAutomaticReconnect()
            .configureLogging(LogLevel.Information)
            .build();

        hubConnection.serverTimeoutInMilliseconds = 60000;

        hubConnection.on("ReceiveMessage", (socketActionType, data) => {
            try {
                console.log('ReceiveMessage - 1', socketActionType, data)

                if (data.notificationId != null) {
                    var notifications = self.state.notifications;
                    if (notifications.findIndex(x => x.notificationId == data.notificationId) == -1) {
                        // if (notifications.length >= 3)
                        //     notifications.pop();
                        notifications.unshift(data);
                        self.updateNotifications(notifications);
                    }
                }
                console.log('ReceiveMessage - 2', socketActionType, notifications);
            } catch (error) {
                console.error('ReceiveMessage', error)
            }
        });

        hubConnection.onclose((e) => {
            console.log('Connection closed!', e);
        });

        hubConnection.start()
            .then(() => {
                console.log('Connection started!')
                // hubConnection.invoke("ReceiveMessage", 0, "Hello")
                //     .catch(err => console.error(err));
            })
            .catch(err => console.log('Error while establishing connection !', err));
    }

    stopConnection = async () => hubConnection.stop();
    
    
    render() {
        return (
            <HubContext.Provider value=
                {{
                    markAsRead: this.markAsRead,
                    markAsReadAll: this.markAsReadAll,
                    notifications: this.state.notifications,
                    stopConnection : this.stopConnection
                }}>
                {this.props.children}
            </HubContext.Provider>
        )
    }
}

export { HubContext, HubContextProvider }