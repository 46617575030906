import _ from "lodash";
import { getPreferredLanguage } from "./localizationManager";
import { LabelPositions, ComponentType, Tables, IToggleConfig, IExportConfig, ILabelProps, ICustomParameterFormat, IPrimaryIdEmptinessComparers } from "./typeConfig";
import { Regexes } from "./validator";

export const IdentityURL = process.env.REACT_APP_IDENTITY_URL;
export const AdminURL = process.env.REACT_APP_ADMIN_URL;
export const GocturApi = process.env.REACT_APP_GOCTURAPI_URL;
export const MCApi = process.env.REACT_APP_MCAPI_URL;
export const RestApi = process.env.REACT_APP_RESTAPI_URL;
export const ApiHR = process.env.REACT_APP_APIHR_URL;
export const ApiPowerBi = process.env.REACT_APP_POWERBI_URL;
export const ApiIoT = process.env.REACT_APP_IOT_API_URL;
export const ApiForms = process.env.REACT_APP_FORM_API_URL;
export const ApiAsset = process.env.REACT_APP_ASSET_API_URL;
export const ApiSupplyChain = process.env.REACT_APP_SUPPLY_CHAIN_URL;
export const KibanaURL = process.env.REACT_APP_KIBANA_URL;
export const SchedulerURL = process.env.REACT_APP_SCHEDULER_URL;
export const ECommerceURL = process.env.REACT_APP_ECOMMERCE_URL;
export const ETLUrl = process.env.REACT_APP_ETL_URL;
export const BlobStorageUrl = 'https://gocturmainblob.blob.core.windows.net/';
export const GocturApi_3 = "";
export const GocturApi_2 = "";
export const Logos = [
    {
        tenant : 3,
        url : `${process.env.PUBLIC_URL}/assets/img/brand/logo_3.svg`
    },
    {
        tenant : 4,
        url : `${process.env.PUBLIC_URL}/assets/img/brand/logo_4.svg`
    },
    {
        tenant : 5,
        url : `${process.env.PUBLIC_URL}/assets/img/brand/logo_5.svg`
    },
]


export const DEFAULT_CACHE_EXPIRATION_MIN = 5;
export const DEFAULT_CACHE_LOCK_TIMEOUT_MS = 5000;
export const DEFAULT_CACHE_STORAGE = "local";

export const DEFAULT_LABEL_POSITION = LabelPositions.LEFT_OF_INPUT;
export const DEFAULT_TABLE_CHOICE = Tables.POWER_GRID;
export const DEFAULT_COMPONENT_TYPE = ComponentType.FORM_CONTROL;
export const DEFAULT_GRID_BLOCK_SIZE = 100;

export const IsRTL = _.get(getPreferredLanguage(), 'isRTL', false);

export const ERROR_MESSAGE_COLUMN_NAME = "errorMessage";

export const DEFAULT_TOGGLE_CONFIG: IToggleConfig = {
    ON: 1,
    OFF: 0
}

export const DEFAULT_EXPORT_CONFIG: IExportConfig = {
    excelConfig: {
        exportable: true,
        importable: true,
        label: null,
        order: 999,
    }
}

export const CHANGE_PASSWORD_URL = '/changePassword';

export const DEFAULT_LANGUAGE_CODE = "en";

export const DEFAULT_LABEL_PROPS: ILabelProps = {
    position: DEFAULT_LABEL_POSITION,
}

//#region Enums

export enum UserStatus {
    DELETED = -1,
    WAITING_FOR_APPROVAL,
    STILL_WORKS,
    QUITTED,
    WAITING_FOR_VERIFICATION
}

export enum ThemeColors {
    SUCCESS = "#4DBD74",
    WARNING = "#F9B115",
    DANGER = "#E6471E",
}

export enum ResourceTypes {
    FormPermission = 7,
    FormMenu = 8,
}

//#endregion Enums

export const WORKING_STATUS_CUSTOM_FORMAT: ICustomParameterFormat = {
    "-1": ThemeColors.DANGER,
    "0": ThemeColors.WARNING,
    "1": ThemeColors.SUCCESS,
    "2": ThemeColors.DANGER,
    "3": ThemeColors.WARNING
}

export const STATUS_CUSTOM_FORMAT: ICustomParameterFormat = {
    "-1": ThemeColors.DANGER,
    "1": ThemeColors.SUCCESS,
}

export const STATUS_CUSTOM_ACTIVE_FORMAT: ICustomParameterFormat = {
    "-1": ThemeColors.DANGER,
    "1": ThemeColors.SUCCESS,
    "2": ThemeColors.WARNING
}

export const TOGGLE_CUSTOM_FORMAT: ICustomParameterFormat = {
    "true": ThemeColors.SUCCESS,
}

export const PRIMARY_ID_EMPTINESS_COMPARERS: IPrimaryIdEmptinessComparers = {
    FALSY: (id, idStr) => !id,
    EMPTY_GUID: (id, idStr) => Regexes.emptyGuid.test(idStr)
}

export default {
    
    Logos,GocturApi_2,GocturApi_3,AdminURL, IdentityURL, GocturApi, MCApi, RestApi,ApiHR, ApiPowerBi, ApiIoT, ApiForms, ApiAsset, ApiSupplyChain, KibanaURL, SchedulerURL, ECommerceURL,
    DEFAULT_LABEL_POSITION, DEFAULT_CACHE_EXPIRATION_MIN, DEFAULT_CACHE_LOCK_TIMEOUT_MS,
    DEFAULT_CACHE_STORAGE, DEFAULT_TABLE_CHOICE, DEFAULT_COMPONENT_TYPE, IsRTL,
    DEFAULT_GRID_BLOCK_SIZE, DEFAULT_TOGGLE_CONFIG, DEFAULT_EXPORT_CONFIG, CHANGE_PASSWORD_URL, UserStatus, DEFAULT_LABEL_PROPS,
    WORKING_STATUS_CUSTOM_FORMAT, STATUS_CUSTOM_FORMAT, TOGGLE_CUSTOM_FORMAT, ERROR_MESSAGE_COLUMN_NAME, STATUS_CUSTOM_ACTIVE_FORMAT,
    PRIMARY_ID_EMPTINESS_COMPARERS,ETLUrl
}