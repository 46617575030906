import React, { Component, Suspense } from 'react';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import history from './common/history'
import _ from 'lodash';
import $ from 'jquery';
import Loadable from 'react-loadable';
import Constants, { ApiForms } from './common/constants';
import { AuthenticationManager } from './common/authentication'
import { makeAuthenticator, Callback } from 'react-oidc';
import { Http } from './common/common';
import { HubContextProvider } from './contexts/HubContext';
import { cacheSet, cacheGet, cacheDelete } from './common/cacheManager';
import { ActionType } from './common/typeConfig';
import { convertListToTranslation } from './common/localizationManager';
import { getFirstAuthorizedPageUrl, checkIfPageExistAndGranted } from './common/authorization';
import { ToastContainer, Bounce } from 'react-toastify';
import { Translations } from "./common/translations";
import './App.scss';
import 'react-toastify/dist/ReactToastify.css';
import { PageInfos } from './pages';
import LoadingOverlay from './components/LoadingOverlay';

const loading = () => <LoadingOverlay isLoading />;

const REDIRECT_LOCATION_SESSION_KEY = 'redirect_location';

// Containers
const DefaultLayout = Loadable({
    loader: () => import('./containers/DefaultLayout'),
    loading
});

// Pages
const Login = Loadable({
    loader: () => import('./views/Routes/CommonPages/Login/Login'),
    loading
});

const Register = Loadable({
    loader: () => import('./views/Routes/CommonPages/Register/Register'),
    loading
});

const ForgotPassword = Loadable({
    loader: () => import('./views/Routes/CommonPages/ForgotPassword/ForgotPassword'),
    loading
});

const ResetPassword = Loadable({
    loader: () => import('./views/Routes/CommonPages/ResetPassword/ResetPassword'),
    loading
});

const ChangePassword = Loadable({
    loader: () => import('./views/Routes/CommonPages/ChangePassword/ChangePassword'),
    loading
});


const Page404 = Loadable({
    loader: () => import('./views/Routes/CommonPages/Page404/Page404'),
    loading
});

const Page500 = Loadable({
    loader: () => import('./views/Routes/CommonPages/Page500/Page500'),
    loading
});

const Page401 = Loadable({
    loader: () => import('./views/Routes/CommonPages/Page401/Page401'),
    loading
});

const FormSubmitPage = Loadable({
    loader: () => import('./views/Routes/OpenPages/Form/FormSubmitPage'),
    loading
});


$(document).on('click', 'a[href="#"]', function (e) {
    e.preventDefault();
});

const toastContainerProps = {
    position: "bottom-right",
    transition: Bounce,
    autoClose: 4000,
    hideProgressBar: false,
    newestOnTop: false,
    closeOnClick: true,
    rtl: false,
    pauseOnFocusLoss: true,
    draggable: true,
    pauseOnHover: true,
    get style() {
        return {
            'bottom-right': { bottom: 40 },
            'bottom-left': { bottom: 40 },
            'bottom-center': { bottom: 40 }
        }[this.position];
    }
}

String.prototype.format = function () {
    var formatted = this;
    for (var arg in arguments) {
        formatted = formatted.replace("{" + arg + "}", arguments[arg]);
    }
    return formatted;
};

class App extends Component {

    componentDidMount() {
        this.cacheFormMenus();
    }

    convertClaimsToJSON = (claims) => {
        var resp = {}

        for (var resource of claims.authResources) {
            if (resp[resource.resourceCode] == null)
                resp[resource.resourceCode] = {}

            resp[resource.resourceCode] = { ...resp[resource.resourceCode], ...resource };
            resp[resource.resourceCode].status = resource.status == 1;
            delete resp[resource.resourceCode].authActions;

            for (var action of resource.authActions) {
                resp[resource.resourceCode][ActionType[action.actionType]] = resp[resource.resourceCode].status
                    && resource.status == 1 && action.status == 1 && action.authUserRights.some(x => x.status == 1);
            }
        }
        console.log('perms', resp);
        return resp;
    }


    saveRequestedLocation = (location) => {
        if (location && location.pathname != '/')
            cacheSet(REDIRECT_LOCATION_SESSION_KEY, location, 5, 'session');
    }

    getRequestedLocation = () => {
        const requestedLocation = cacheGet(REDIRECT_LOCATION_SESSION_KEY, 'session');
        cacheDelete(REDIRECT_LOCATION_SESSION_KEY, 'session');
        return requestedLocation;
    }

    cacheFormMenus = async () => {
        let isLoggedIn = AuthenticationManager.IsLoggedIn();

        if (isLoggedIn) {
            return await Http.get(`${ApiForms}/formScenario/listFormsAsMenu`).then(response => {
                let resourceCodes = cacheGet('authorizationClaims')
                let formMenus = response.data.data;
                let pageInfosAsArray = Object.keys(PageInfos).map(key => PageInfos[key]);
                let formMenusObject = {};
                for (const formMenu of formMenus) {
                    let parentExistOnPageInfos = pageInfosAsArray.includes(x => x.resourceCode === formMenu.parentResourceCode);

                    if (!formMenusObject.hasOwnProperty(formMenu.parentResourceCode) && !parentExistOnPageInfos) {
                        formMenusObject[formMenu.parentResourceCode] = {
                            resourceCode: formMenu.parentResourceCode,
                            name: resourceCodes && resourceCodes[formMenu.parentResourceCode] ? resourceCodes[formMenu.parentResourceCode].resourceName : formMenu.parentResourceCode,
                            defaultOpen: false,
                            icon: 'cui-note'
                        };
                    }



                    let subMenuExistOnPageInfos = pageInfosAsArray.includes(x => x.resourceCode === formMenu.resourceCode && x.parentResourceCode === formMenu.parentResourceCode);

                    if (!subMenuExistOnPageInfos && !formMenusObject.hasOwnProperty(formMenu.resourceCode + '_' + formMenu.parentResourceCode)) {
                        formMenusObject[formMenu.resourceCode + '_' + formMenu.parentResourceCode] = {
                            name: resourceCodes && resourceCodes[formMenu.resourceCode] ? resourceCodes[formMenu.resourceCode].resourceName : formMenu.resourceCode,
                            resourceCode: formMenu.resourceCode,
                            parentResourceCode: formMenu.parentResourceCode,
                            url: '/' + formMenu.resourceCode,
                            icon: 'cui-monitor'
                        };
                    }


                    formMenusObject[formMenu.formUniqueCode] = {
                        name: formMenu.name,
                        url: `/${formMenu.formUniqueCode}`,
                        resourceCode: formMenu.resourceCode,
                        parentResourceCode: formMenu.resourceCode,
                        icon: formMenu.icon ? formMenu.icon : 'cui-paperclip',
                        showInSidebar: false
                    };
                }

                cacheSet('FormMenus', formMenusObject)
                return true;

            })
                .catch(err => {
                    console.log(err);
                    if (!cacheGet('FormMenus'))
                        cacheSet('FormMenus', {});
                    return false
                })
        }
    }

    render() {


        return (<>
            <ToastContainer {...toastContainerProps} />
            <Router history={history}>
                <Suspense fallback={loading}>

                    <Switch>

                        <Route exact path="/logout" name="Login Page" component={Login} />
                        <Route exact path="/login" name="Login Page" render={(props) => AuthenticationManager.IsLoggedIn() ? <Redirect to={getFirstAuthorizedPageUrl()} /> : <Login {...props} />} />
                        <Route exact path="/register" name="Register Page" component={Register} />
                        <Route exact path="/forgotPassword" name="Forgot Password" component={ForgotPassword} />
                        <Route exact path="/resetPassword/:unique" name="Reset Password" component={ResetPassword} />
                        <Route exact path="/401" name="Page 401" component={Page401} />
                        <Route exact path="/404" name="Page 404" component={Page404} />
                        <Route exact path="/500" name="Page 500" component={Page500} />
                        <Route exact path="/form-doldur/:formUniqueName" name="Form Submit Page" component={FormSubmitPage} />
                        <Route
                            path="/callback"
                            render={routeProps => (
                                <>
                                    <LoadingOverlay isLoading />
                                    <Callback
                                        userManager={AuthenticationManager.UserManager}
                                        onSuccess={async (user) => {
                                            // `user.state` will reflect the state that was passed in via signinArgs.
                                            let urlToRedirect = await Http.post(`${Constants.IdentityURL}/Account/GetResources`, undefined, { timeout: 120000 })
                                                .then((response) => { //Successful http response,
                                                    try {
                                                        let data = response.data.data;
                                                        let session = data.session;
                                                        let user = session.currentUser;

                                                        // set the authTemplate which is returned from request's response
                                                        let authTemplate = data.authTemplate;


                                                        cacheSet("access_token", session.token, 600);
                                                        cacheSet("profile", user, 600);
                                                        cacheSet("authorizationClaims", this.convertClaimsToJSON(session.authorizationClaims), 600);
                                                        cacheSet("translations", _.assign(Translations, convertListToTranslation(data.labels)), 600);
                                                        cacheSet("notifications", data.notifications.list, 600);

                                                        const requestedLocation = this.getRequestedLocation();
                                                        let requestedLocationHref;

                                                        if (requestedLocation && checkIfPageExistAndGranted(requestedLocation.pathname))
                                                            requestedLocationHref = requestedLocation.pathname + requestedLocation.search;

                                                        // if there is a default page for the template then redirect to it
                                                        // else redirect to requested location if exists or first authorized one
                                                        return user.shouldChangePassword ? Constants.CHANGE_PASSWORD_URL
                                                            : (requestedLocationHref ? requestedLocationHref
                                                                : checkIfPageExistAndGranted(authTemplate.templateDefaultPage) || getFirstAuthorizedPageUrl());


                                                    } catch (error) {
                                                        console.log(error);
                                                        return '/logout';
                                                    }
                                                })
                                                .catch((e) => {
                                                    console.error(e);
                                                    return '/logout';
                                                });


                                            await this.cacheFormMenus();

                                            let returnUrl = localStorage.getItem('returnUrl');

                                            if (returnUrl !== '' && returnUrl !== null && returnUrl !== undefined)
                                                window.location.href = returnUrl;
                                            else
                                                window.location.href = urlToRedirect;


                                        }}
                                        onError={(e) => {
                                            console.error(e);
                                            routeProps.history.push("/login", { isLoginFailed: true })
                                        }}
                                    />
                                </>
                            )}
                        />

                        {!AuthenticationManager.IsLoggedIn()
                            ? <Route exact path="*" render={(props) => {
                                this.saveRequestedLocation(props.location);
                                history.push('login');
                                return loading;
                            }} />
                            : null}

                        {AuthenticationManager.IsLoggedIn() && cacheGet('profile') && cacheGet('profile').shouldChangePassword
                            ? <Route exact path="*" name="Change Password" component={ChangePassword} />
                            : null}

                        <Route path="*" name="Home" render={routeProps => (<HubContextProvider>
                            <DefaultLayout {...routeProps} />
                        </HubContextProvider>)} />

                    </Switch>
                </Suspense>
            </Router>
        </>
        )
    }
}

export default App;