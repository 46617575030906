import React from 'react';
//import { Http } from '../common/common';
import { getLocalizedTextWithValue } from '../common/localizationManager';


//INSERT GENERATOR
// import _ from 'lodash';
// import type1 from '../entities/Management/CommunicationMappings';

// var labels = [];
// for (const item in type1) {
//     if (type1[item].label)
//         labels.push({ key: item, label: type1[item].label });
// }

// var destArray = _.uniq(labels, 'label');
// var scripts = "";
// destArray.map(x => {
//     scripts += `INSERT INTO "coreParameters"(
//     "keyCode", "parentValue", value, "orderIndex", "language", status, description, translations)
//     VALUES ('${x.label.replace(/ /g, '_').toUpperCase()}', 0, 0, 0, 'TR', 1, '', '{"TR":"${x.label}","EN":"${x.label}"}');

//     `
// });
// console.warn('uniq', scripts);
//INSERT GENERATOR

export default class LocalizeTextCls extends React.Component {

    constructor(props) {
        super(props);

        var text = getLocalizedTextWithValue(this.props.keyCode, this.props.value, this.props.params);
        if (this.props.format)
            text = this.props.format(text);

        this.state = {
            text: text,
            //lang: ''
        };
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return this.props.keyCode != nextProps.keyCode;
    }
    // componentWillMount() {
    //   console.log(this.props.id)
    //   if (this.state.label == null) {
    //     var localizeText = getLocalizedText(this.props.id);

    //     if (localizeText != null && localizeText != "") {
    //       this.setState({ label: localizeText });
    //     }
    //     else {
    //       console.log('getting localized text for ' + this.props.id)
    //       Http.post('parameter/getLocalizedParam', {
    //         keyCode: this.props.id
    //       })
    //         .then(response => { //Successful http response
    //           var label = response.data.data;
    //           console.log('localized text for ' + this.props.id + ' = ' + label);
    //           this.setState({ label });
    //         })
    //     }
    //   }
    // }

    // componentDidMount() {

    // }

    render() {
        return (
            <>{this.state.text}</>
        )
    }
}